
// Libraries
import { Vue, Component, Prop } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

// Mixins

@Component({
  components: {
    RouterLink,
  },
})
export default class TableDataAtom extends Vue {
  @Prop() content: string | number;
  checkDataType() {
    if (typeof this.content === "object" && this.content !== null)
      return "object";
    return "simple";
  }
}
